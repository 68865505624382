import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';

import { PageChangeEvent, SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import {
  HighlightArea,
  highlightPlugin,
  RenderHighlightsProps,
  Trigger,
} from '@react-pdf-viewer/highlight';
import {
  pageNavigationPlugin,
  RenderCurrentPageLabelProps,
} from '@react-pdf-viewer/page-navigation';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import { searchPlugin } from '@react-pdf-viewer/search';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useMemo, useState } from 'react';

import PdfArrow from '@/assets/images/arrow-icon.svg';
import { DataRoomClientFile } from '@/common/types';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { RightSidePanel } from '@/pages/overview/common/side-panel/RightSidePanel';
import { useOverview } from '@/pages/overview/common/utils';
import { FinancingRightsPanel } from '@/pages/overview/financing-rights/common/FinancingRightsPanel';
import { VetoData } from '@/pages/overview/financing-rights/common/utils';
import { PdfDualHeader } from '@/pages/pdf-viewer/common/PdfDualHeader';
import { PdfHeader } from '@/pages/pdf-viewer/common/PdfHeader';
import { DualViewer } from '@/pages/pdf-viewer/DualViewer';

export interface Reference {
  displayName: string;
  name: string;
  missing: boolean;
  highlight: number[];
  generatedTitle?: string;
}

export interface Signatory {
  signatory: string;
  status: boolean;
}

export interface FileDetails {
  title: string;
  type: string;
  effective_date: string;
  summary: string;
  signatories: Signatory[];
  references: Reference[];
  referenced_by: Reference[];
  vetoData: VetoData;
}

interface PdfViewerProps {
  title: string;
  originalTitle?: string;
  fileUrl: string;
  multipleDocuments: boolean;
  missingSignature?: boolean | null;
  closePdfViewer: (val: boolean) => void;
  navigateForwardFunction?: () => void;
  navigateBackFunction?: () => void;
  highlightLocation?: number[];
  selectSecondFile?: (name: string) => void;
  secondSelectedFile?: DataRoomClientFile;
  secondSelectedFileUrl?: string;
  sidebarFocus?: string;
}

export const PdfViewer = ({
  title,
  originalTitle,
  fileUrl,
  multipleDocuments,
  missingSignature,
  closePdfViewer,
  navigateForwardFunction,
  navigateBackFunction,
  highlightLocation,
  selectSecondFile,
  secondSelectedFile,
  secondSelectedFileUrl,
  sidebarFocus,
}: PdfViewerProps) => {
  const [highlightedArea, setHighLightedArea] = useState<HighlightArea[]>([]);
  const [focusedReference, setFocusedReference] = useState<HighlightArea>();

  const { matter, selectedReferenceFocus } = useOverview();
  const { selectedFileDetails, isDualViewerOpen, setIsDualViewerOpen } = useDataRoom();

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const searchPluginInstance = searchPlugin({
    enableShortcuts: true,
  });
  const zoomPluginInstance = zoomPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();
  const rotatePluginInstance = rotatePlugin();
  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: () => {
      const fileName = title;
      return `${fileName}`;
    },
  });
  const { DownloadButton } = getFilePluginInstance;

  const { ShowSearchPopoverButton } = searchPluginInstance;
  const {
    CurrentPageInput,
    GoToNextPageButton,
    GoToPreviousPage,
    CurrentPageLabel,
    jumpToNextPage,
    jumpToPreviousPage,
  } = pageNavigationPluginInstance;
  const { Zoom, ZoomInButton, ZoomOutButton } = zoomPluginInstance;

  const { EnterFullScreenButton } = fullScreenPluginInstance;
  const { RotateBackwardButton, RotateForwardButton } = rotatePluginInstance;

  const handlePageChange = (e: PageChangeEvent) => {
    localStorage.setItem('current-page', `${e.currentPage}`);
  };

  const [hoveredHighlight, setHoveredHighlight] = useState<HighlightArea | null>(null);

  const vetoDataExists = selectedFileDetails?.vetoData.clausesRevised;

  const vetoDataReferences = useMemo(() => {
    if (vetoDataExists) {
      return vetoDataExists.rights_clauses;
    }
    return [];
  }, [vetoDataExists]);

  const vetoDataHighlights = useMemo(() => {
    if (!vetoDataExists) return [];

    return vetoDataReferences.map((item) => {
      const parsedLocations = JSON.parse(item.location);
      return parsedLocations.locations;
    });
  }, [vetoDataExists, vetoDataReferences]);

  const fileDetailsReferences = useMemo(
    () => selectedFileDetails?.references,
    [selectedFileDetails?.references],
  );

  const flagHighlightLocation = useMemo(() => {
    if (sidebarFocus === 'Veto') {
      return vetoDataHighlights.length > 0 ? vetoDataHighlights : [0, 0, 0, 0, 0];
    }
    return fileDetailsReferences?.[0]?.highlight || highlightLocation || [0, 0, 0, 0, 0];
  }, [highlightLocation, fileDetailsReferences, sidebarFocus, vetoDataHighlights]);

  useEffect(() => {
    const highlights =
      sidebarFocus === 'Veto'
        ? vetoDataHighlights
        : (fileDetailsReferences?.length || 0) > 0
          ? fileDetailsReferences?.map((ref) => ref.highlight) || [flagHighlightLocation]
          : [flagHighlightLocation];

    const newAreas = highlights?.map((highlight) => {
      return {
        height: highlight[4],
        left: highlight[1],
        pageIndex: highlight[0],
        top: highlight[2],
        width: highlight[3],
      };
    });

    if (newAreas) {
      setHighLightedArea(newAreas);
    }
  }, [flagHighlightLocation, fileDetailsReferences, sidebarFocus, vetoDataHighlights]);

  const compareHighlights = (
    pageHighlight: HighlightArea,
    referenceHighlight: HighlightArea | undefined,
  ) => {
    return JSON.stringify(pageHighlight) === JSON.stringify(referenceHighlight);
  };

  const renderHighlights = (props: RenderHighlightsProps) => {
    return (
      <>
        {selectedReferenceFocus === 'referenced' && (
          <div>
            {uniqBy(
              highlightedArea.filter(
                (area: HighlightArea) => area.pageIndex - 1 === props.pageIndex,
              ),
              (val) => {
                return val.pageIndex + '~' + val.top;
              },
            ).map((area: HighlightArea, idx) => (
              <div
                key={idx}
                onMouseEnter={() => setHoveredHighlight(area)}
                onMouseLeave={() => setHoveredHighlight(null)}
                style={Object.assign(
                  {},
                  {
                    opacity: 0.4,
                    borderRadius: '5px',
                    background: `${
                      compareHighlights(area, focusedReference) ? '#74cfed' : '#afd6e3'
                    }`,
                    filter: 'blur(4px)',
                    zIndex: 100,
                  },
                  props.getCssProperties(
                    {
                      height: area.height + 2,
                      left: Math.max(area.left - 1, 0),
                      pageIndex: area.pageIndex,
                      top: Math.max(area.top - 1, 0),
                      width: area.width + 4,
                    },
                    props.rotation,
                  ),
                )}
              />
            ))}
          </div>
        )}
      </>
    );
  };

  const highlightPluginInstance = highlightPlugin({ trigger: Trigger.None, renderHighlights });
  const { jumpToHighlightArea } = highlightPluginInstance;

  const initialPage = parseInt(localStorage.getItem('current-page') ?? '0', 10);

  const handlePdfExit = useCallback(() => {
    localStorage.setItem('current-page', '0');
    setIsDualViewerOpen(false);
    closePdfViewer(true);
  }, [closePdfViewer, setIsDualViewerOpen]);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handlePdfExit();
      }
      if (event.key === 'ArrowDown') {
        jumpToNextPage();
      }
      if (event.key === 'ArrowUp') {
        jumpToPreviousPage();
      }
      if (multipleDocuments && !secondSelectedFile) {
        if (event.key === 'ArrowRight' && navigateForwardFunction) {
          navigateForwardFunction();
        }
        if (event.key === 'ArrowLeft' && navigateBackFunction) {
          navigateBackFunction();
        }
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [
    handlePdfExit,
    jumpToNextPage,
    jumpToPreviousPage,
    multipleDocuments,
    navigateBackFunction,
    navigateForwardFunction,
    secondSelectedFile,
  ]);

  const handleJumpToHighlight = (highlight: number[]) => {
    const referencedHighlighArea: HighlightArea = {
      height: highlight[4],
      left: highlight[1],
      pageIndex: highlight[0] - 1,
      top: highlight[2] - 5,
      width: highlight[3],
    };

    jumpToHighlightArea(referencedHighlighArea);
  };

  const handleReferenceMouseOver = (highlight: number[]) => {
    if (highlight.length === 0) setFocusedReference(undefined);
    else {
      const referencedHighlighArea: HighlightArea = {
        height: highlight[4],
        left: highlight[1],
        pageIndex: highlight[0],
        top: highlight[2],
        width: highlight[3],
      };

      setFocusedReference(referencedHighlighArea);
    }
  };

  return (
    <div
      className="absolute left-0 top-0 z-50 flex size-full flex-col overflow-hidden bg-[#0c0c0c86]"
      data-testid="pdf-viewer"
    >
      {isDualViewerOpen && secondSelectedFile ? (
        <PdfDualHeader
          firstDocumentDisplayName={title}
          firstDocumentName={originalTitle}
          secondDocumentDisplayName={secondSelectedFile.displayName}
          secondDocumentName={secondSelectedFile.name}
          selectSecondFile={selectSecondFile}
          onClose={handlePdfExit}
        />
      ) : (
        <PdfHeader
          title={title}
          fileUrl={fileUrl}
          missingSignature={missingSignature}
          originalTitle={originalTitle}
          onClose={handlePdfExit}
        />
      )}
      <div className="flex size-full">
        <div className="relative flex h-[calc(100vh-120px)] w-full items-center rounded-[5px] bg-white">
          <div className="size-full">
            <div className="flex items-center border-b border-[#363636] bg-[#b3b3b3] p-[4px]">
              {multipleDocuments && !secondSelectedFile && (
                <div className="z-40 mr-4 flex shrink-0 translate-y-[calc(45vh)] items-center justify-center rounded-full border-2 border-[#797979] bg-container-dark p-2 active:border-marveri-white">
                  <MarveriIcon
                    icon={PdfArrow}
                    iconStyle="rotate-90 h-6 w-6 -translate-x-[1.2px]"
                    onClick={navigateBackFunction}
                    iconType="other"
                  />
                </div>
              )}
              <div className="flex items-center rounded-[5px]">
                <ShowSearchPopoverButton />
                <div className="rounded-[5px]">
                  <GoToPreviousPage />
                </div>
                <div className="w-12 p-[2px]">
                  <CurrentPageInput />
                </div>
                <CurrentPageLabel>
                  {(props: RenderCurrentPageLabelProps) => (
                    <div className="flex items-center">
                      <div className="p-[2px] text-[#474747]">/ {`${props.numberOfPages}`}</div>
                    </div>
                  )}
                </CurrentPageLabel>
                <div className="rounded-[5px]">
                  <GoToNextPageButton />
                </div>
              </div>
              <div className="mx-auto flex items-center">
                <ZoomOutButton />
                <Zoom />
                <ZoomInButton />
              </div>
              <div className="ml-[9.2%] flex">
                <EnterFullScreenButton />
                <RotateForwardButton />
                <RotateBackwardButton />
                <DownloadButton />
              </div>
              {multipleDocuments && !secondSelectedFile && (
                <div className="z-40 ml-4 flex shrink-0 translate-y-[calc(45vh)] items-center justify-center rounded-full border-2 border-[#797979] bg-container-dark p-2 active:border-marveri-white">
                  <MarveriIcon
                    icon={PdfArrow}
                    iconStyle="-rotate-90 h-6 w-6 translate-x-[1.2px]"
                    onClick={navigateForwardFunction}
                    iconType="other"
                  />
                </div>
              )}
            </div>
            <Viewer
              onPageChange={handlePageChange}
              fileUrl={fileUrl}
              initialPage={initialPage}
              defaultScale={SpecialZoomLevel.PageWidth}
              plugins={[
                pageNavigationPluginInstance,
                searchPluginInstance,
                zoomPluginInstance,
                fullScreenPluginInstance,
                rotatePluginInstance,
                highlightPluginInstance,
                getFilePluginInstance,
              ]}
            />
          </div>
        </div>
        {isDualViewerOpen && secondSelectedFileUrl && (
          <DualViewer fileUrl={secondSelectedFileUrl} />
        )}
        {matter.fileUploadPercentComplete === 100 && selectedFileDetails && !isDualViewerOpen && (
          <>
            {sidebarFocus === 'Veto' ? (
              <FinancingRightsPanel
                hoveredHighlight={hoveredHighlight}
                handleJumpToHighlight={handleJumpToHighlight}
                handleReferenceMouseOver={handleReferenceMouseOver}
              />
            ) : (
              <RightSidePanel
                pdfInteraction={true}
                hoveredHighlight={hoveredHighlight}
                handleJumpToHighlight={handleJumpToHighlight}
                handleReferenceMouseOver={handleReferenceMouseOver}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
